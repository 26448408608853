import { addDays } from "date-fns";

import { useStoredTimeout } from "./useStoredTimeout";

export const useStoredConfirmation = ({
  key,
  message,
  expiresInDays,
  skip,
}: {
  key: string;
  message: string;
  expiresInDays: number;
  skip: boolean;
}) => {
  const { expired, setExpiry } = useStoredTimeout({ key });

  if (skip) {
    return false;
  }

  if (expired === false) {
    return true;
  }

  if (confirm(message)) {
    setExpiry(addDays(new Date(), expiresInDays));
    return true;
  }

  return false;
};
