import { useMemo } from "react";

import { useUserInvitations } from "~/browser/welcome/pages/useUserInvitations";
import { useUserContext } from "~/contexts/UserContext";

import { useStoredConfirmation } from "./useStoredConfirmation";
import useWorkspaceSlug from "./useWorkspaceSlug";

export const useStarlingOverride = () => {
  const slug = useWorkspaceSlug();
  const { user, authenticated } = useUserContext();
  const isStarling = useMemo(() => user.email?.endsWith("@murmur.com"), [user]);
  const isMember = !!user.workspaces?.some(({ _id }) => _id === slug);
  const isGuest = !!user.guestOfWorkspaces?.some(({ _id }) => _id === slug);
  const invitations = useUserInvitations();
  const isInvited = !!invitations.some(
    ({ workspace }) => workspace._id === slug
  );

  const confirmedOverride = useStoredConfirmation({
    key: `override:${slug}`,
    message: `You are about to access the ${slug} workspace using Starling override permissions.

According to our Data Policy agreement (https://app.murmur.com/603e637b64607c0008eb1bdf/agreements/61b3a95bf17c920007114755), we require permission from a workspace admin or member before accessing their private data.

Are you sure you wish to continue?`,
    expiresInDays: 1,
    skip:
      !slug ||
      !authenticated ||
      !isStarling ||
      isMember ||
      isGuest ||
      isInvited,
  });

  return confirmedOverride;
};
