import { Popover, PopoverProps } from "@mui/material";
import Image from "next/future/image";
import { FC, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import NewSparklesIcon from "~/assets/icons/new-sparkles.svg";
import addGuestsBackground from "~/assets/images/add-guests-background.png";
import AddGuestsImage from "~/assets/images/add-guests.svg";
import avatarAlice from "~/assets/images/avatar-alice-shepherd-tiny.png";
import avatarMark from "~/assets/images/avatar-mark-scout-tiny.png";
import avatarOlivia from "~/assets/images/avatar-olivia-jacobs-tiny.png";
import acknowledgmentBackground from "~/assets/images/new-feature-acknowledgment-background.png";
import AcknowledgmentImage from "~/assets/images/new-feature-acknowledgment.svg";
import Button from "~/components/Button";
import { useUserContext } from "~/contexts/UserContext";

const CURRENT_FEATURES = [
  {
    id: "AddGuestsToYourWorkspace" as const,
    linkToArticleId: 6746822,
    title: "Add Guests to your Workspace",
    description:
      "Invite collaborators to specific proposals without giving them full access to your workspace.",
    image: () => (
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          align-items: end;
          justify-content: start;
          padding-left: 1.25rem;
          background-image: url("${addGuestsBackground.src}");
        `}
      >
        <AddGuestsImage />
      </div>
    ),
  },
  {
    id: "Acknowledgment" as const,
    linkToArticleId: 6681551,
    title: "Request Acknowledgment",
    description:
      "Send an agreement to people who weren't involved in the decision, so it's clear they have read the decision and understand its impact on them.",
    image: () => (
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: end;
          justify-content: start;
          padding-left: 0.5rem;
          padding-bottom: 0.5rem;
          background-image: url("${acknowledgmentBackground.src}");
        `}
      >
        <AcknowledgmentImage />
        <Image
          css={css`
            position: absolute;
            top: 46px;
            left: 43px;
          `}
          src={avatarOlivia}
        />
        <Image
          css={css`
            position: absolute;
            top: 88px;
            left: 43px;
          `}
          src={avatarMark}
        />
        <Image
          css={css`
            position: absolute;
            top: 132px;
            left: 43px;
          `}
          src={avatarAlice}
        />
      </div>
    ),
  },
];

const Tag = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  color: ${({ theme }) => theme.colors.pumpkin};
  background-color: ${({ theme }) => theme.colors.sorbetLight};
  border-radius: 0.25rem;
  padding: 0.1875rem 0.3125rem;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

const NewTag = () => (
  <Tag>
    <NewSparklesIcon />
    <span>New</span>
  </Tag>
);

const NewFeaturePopup: FC<
  PopoverProps & {
    open: boolean;
    onClose: () => void;
    title: string;
    description: string;
    buttonText?: string;
    learnMoreAction?: () => void;
    learnMoreText?: string;
    image?: JSX.Element;
  }
> = ({
  open,
  onClose,
  title,
  description,
  buttonText = "Got it!",
  learnMoreAction,
  learnMoreText = "Learn more",
  image,
  ...rest
}) => {
  const handleLearnMore = useCallback(() => {
    learnMoreAction?.();
    onClose?.();
  }, [learnMoreAction, onClose]);

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      // top: MAX_SAFE_INTEGER will mean it is always at the bottom of the window
      // use `marginThreshold` to move the popover inward more than the default (16px)
      anchorPosition={{
        top: Number.MAX_SAFE_INTEGER,
        left: Number.MAX_SAFE_INTEGER,
      }}
      marginThreshold={65}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      hideBackdrop={true}
      css={css`
        &.MuiModal-root.MuiPopover-root {
          /* background-color: red; */
          width: auto;
          pointer-events: none;
        }
      `}
      {...rest}
    >
      <div
        css={css`
          width: 21rem;
          border-radius: 0.5rem;
          background-color: ${({ theme }) => theme.colors.moonLight};
          pointer-events: auto;
        `}
      >
        {image && (
          <div
            css={css`
              background-color: ${({ theme }) => theme.colors.speech10};
              height: 15.125rem;
            `}
          >
            {image}
          </div>
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1.25rem;
          `}
        >
          <div>
            <NewTag />
          </div>
          <div>
            <h3
              css={css`
                font-weight: ${({ theme }) => theme.weights.medium};
                padding: 0;
                margin: 0;
              `}
            >
              {title}
            </h3>
          </div>
          <div
            css={css`
              line-height: 1.5;
            `}
          >
            {description}
          </div>
          <div
            css={css`
              display: flex;
              justify-content: end;
              align-items: center;
            `}
          >
            {!!learnMoreAction && (
              <Button
                variant="secondary"
                css={css`
                  width: auto;
                `}
                onClick={handleLearnMore}
              >
                {learnMoreText}
              </Button>
            )}
            <Button
              css={css`
                width: auto;
                padding: 0.5rem 1rem;
              `}
              onClick={onClose}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </Popover>
  );
};

const getNextFeature = (seenFeatures?: string[]) => (
  currentFeatureId: string | undefined
): typeof CURRENT_FEATURES[number] | undefined => {
  const nonSeenFeatures = seenFeatures
    ? CURRENT_FEATURES.filter(
        (feature) =>
          !seenFeatures.some((seenFeature) => seenFeature === feature.id)
      )
    : CURRENT_FEATURES;
  const currentFeatureIndex = nonSeenFeatures.findIndex(
    (feature) => feature.id === currentFeatureId
  );
  if (currentFeatureIndex + 1 < nonSeenFeatures.length) {
    return nonSeenFeatures[currentFeatureIndex + 1];
  }

  return undefined;
};

export const NewFeaturePopupConnected: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { user, loading: loadingUser, markFeatureAsSeen } = useUserContext();
  const [newFeatureToShow, setNewFeatureToShow] = useState<
    typeof CURRENT_FEATURES[number] | undefined
  >(undefined);
  const handleNewFeaturePopupClose = useCallback(() => {
    if (newFeatureToShow) {
      markFeatureAsSeen(newFeatureToShow.id);
      setNewFeatureToShow(undefined);
      setOpen(false);
    }
  }, [markFeatureAsSeen, newFeatureToShow]);

  useEffect(() => {
    if (loadingUser || !user) return;

    setNewFeatureToShow((currentFeature) =>
      getNextFeature(user.featuresSeen)(currentFeature?.id)
    );
  }, [loadingUser, user]);

  useEffect(() => {
    if (newFeatureToShow) {
      setOpen(true);
    }
  }, [newFeatureToShow]);

  return (
    <NewFeaturePopup
      open={open}
      onClose={handleNewFeaturePopupClose}
      title={newFeatureToShow?.title || ""}
      description={newFeatureToShow?.description || ""}
      image={newFeatureToShow ? <newFeatureToShow.image /> : <div />}
    />
  );
};
