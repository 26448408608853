import { sortBy } from "lodash";
import React, { FC, memo, useEffect } from "react";
import styled, { css } from "styled-components";

import {
  AgreementActivityItem,
  ProposalActivityItem,
} from "~/browser/dashboard/components/ActivityTypes";
import GettingStartedChecklist from "~/browser/dashboard/components/GettingStartedChecklist";
import WorkspaceNotFound from "~/components/404/WorkspaceNotFound";
import PageTitle from "~/components/PageTitle";
import ErrorDisplay, {
  ErrorActions,
  formatApolloError,
} from "~/containers/Common/Error/ErrorDisplay";
import WaitRoom from "~/containers/Common/WaitRoom";
import { useAnalytics } from "~/contexts/AnalyticsContext";
import { useWorkspaceContext } from "~/contexts/WorkspaceContext";
import {
  ActivityAgreementSummaryFragment,
  ActivityProposalSummaryFragment,
  useGetActivitiesByWorkspaceQuery,
} from "~/graphql/generated";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";

import { Activities } from "./styles";

const DashboardWrapper = styled.div`
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-around;
  flex-shrink: 1;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

type Activities = Array<
  ActivityAgreementSummaryFragment | ActivityProposalSummaryFragment
>;

const ActivitiesSuccess = ({ activities }: { activities?: Activities }) => {
  const { page } = useAnalytics();
  const { slug, isAdmin, isGuest } = useWorkspaceContext();

  useEffect(() => {
    page("Dashboard", { workspace: slug });
  }, [page, slug]);

  return (
    <DashboardWrapper>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 35rem;
          margin: 0 0.5rem 0 1rem;
          gap: 2.25rem;
          flex-shrink: 1;
        `}
      >
        <div>
          <PageTitle>To-do&apos;s</PageTitle>
          <div>
            Here you'll find proposals and agreements that need your attention.
          </div>
          <Activities>
            {activities?.length ? (
              sortBy(activities, (a) => new Date(a.endDate)).map(
                (activity, idx) => {
                  if ("agreement" in activity) {
                    return (
                      <AgreementActivityItem key={idx} activity={activity} />
                    );
                  }
                  if ("proposal" in activity) {
                    return (
                      <ProposalActivityItem key={idx} activity={activity} />
                    );
                  }
                  return null;
                }
              )
            ) : (
              <div
                css={css`
                  background-color: ${({ theme }) => theme.colors.moon};
                  padding: 2rem 1rem;
                  font-size: 1.25rem;
                  text-align: center;
                `}
              >
                🚀 You're all caught up!
              </div>
            )}
          </Activities>
        </div>
      </div>
      <GettingStartedChecklist isAdmin={isAdmin} isGuest={isGuest} />
    </DashboardWrapper>
  );
};

const DashboardActivities: FC = () => {
  const slug = useWorkspaceSlug();

  const { error, data } = useGetActivitiesByWorkspaceQuery({
    variables: {
      activitiesParams: {
        workspaceId: slug,
      },
    },
    skip: !slug,
  });

  if (!data) return <WaitRoom />;

  if (error) {
    if (error.graphQLErrors?.[0]?.extensions?.exception.code === "NOT_FOUND") {
      return <WorkspaceNotFound />;
    }
    return (
      <ErrorDisplay
        errorMessage={formatApolloError(error)}
        actions={[ErrorActions.MessageSupport]}
      />
    );
  }

  return <ActivitiesSuccess activities={data.activities} />;
};

export default memo(DashboardActivities);
