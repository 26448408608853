import type { FC } from "react";
import React from "react";

import { ActivityItem } from "~/browser/dashboard/components/ActivityItem";
import { useWorkspaceContext } from "~/contexts/WorkspaceContext";
import {
  ActivityAgreementSummaryFragment,
  ActivityProposalSummaryFragment,
  ActivityType,
  Round,
  Status,
} from "~/graphql/generated";
import useTimeToAdvance from "~/hooks/useTimeToAdvance";
import { getSimpleEditorTextOrLegacyText } from "~/utils/getTextFromRootNode";

import {
  AcknowledgeIcon,
  FeedbackIcon,
  FinalizeIcon,
  ImproveIcon,
  UnderstandIcon,
} from "./styles";

type ActivityAgreementSummary =
  | ActivityAgreementSummaryFragment["agreement"]
  | ActivityProposalSummaryFragment["proposal"];

export function getActionVerb(type: ActivityType) {
  switch (type) {
    case ActivityType.FeedbackRequest:
      return "Provide feedback";
    case ActivityType.AskQuestions:
      return "Ask questions";
    case ActivityType.AnswerQuestions:
      return "Answer questions";
    case ActivityType.SuggestImprovements:
      return "Make suggestions";
    case ActivityType.Improve:
      return "Make improvements";
    case ActivityType.Decide:
      return "Decide";
    case ActivityType.MakeItWork:
      return "Make it work";
    case ActivityType.ResolveObjections:
      return "Resolve objections";
    case ActivityType.AcknowledgeRequest:
      return "Acknowledge";
  }
}

const ProposalRoundIcon: FC<{ round: Round }> = ({ round }) => {
  switch (round) {
    case Round.Understand:
      return <UnderstandIcon />;
    case Round.Improve:
      return <ImproveIcon />;
    case Round.Finalize:
      return <FinalizeIcon />;
  }
};

export function getSummary(agreement: ActivityAgreementSummary) {
  const agreementTitle = getSimpleEditorTextOrLegacyText({
    nodeStr: agreement.workingDocument.title,
  });
  const proposerName =
    "proposer" in agreement && agreement.proposer.displayName;
  return (
    <>
      <span>{agreementTitle}</span>
      {proposerName && <span className="author">by {proposerName}</span>}
    </>
  );
}

export function getHref(
  workspaceSlug: string,
  agreement: ActivityAgreementSummary
) {
  const agreementUrlType =
    agreement.status === Status.Proposal ? "proposals" : "agreements";
  return `/${workspaceSlug}/${agreementUrlType}/${agreement._id}`;
}

export const ActivityText = ({
  endDate,
  action,
  type,
}: {
  endDate: Date;
  action: string;
  type: ActivityType;
}) => {
  const timeLeft = useTimeToAdvance(endDate);
  const showTimeLeft = type !== ActivityType.AcknowledgeRequest;
  return (
    <span>
      {showTimeLeft ? `${timeLeft} left` : ""} {action}
    </span>
  );
};

export const AgreementActivityItem = ({
  activity,
}: {
  activity: ActivityAgreementSummaryFragment;
}) => {
  const { slug } = useWorkspaceContext();
  const { type, agreement, endDate } = activity;
  const icon =
    type === ActivityType.AcknowledgeRequest ? (
      <AcknowledgeIcon />
    ) : (
      <FeedbackIcon />
    );
  return (
    <ActivityItem
      type={type}
      title={getSimpleEditorTextOrLegacyText({
        nodeStr: agreement.workingDocument.title,
      })}
      endDate={new Date(endDate)}
      description={getActionVerb(type)}
      href={getHref(slug, agreement)}
      icon={icon}
    />
  );
};

export const ProposalActivityItem = ({
  activity,
}: {
  activity: ActivityProposalSummaryFragment;
}) => {
  const { slug } = useWorkspaceContext();
  const { type, proposal, endDate } = activity;
  return (
    <ActivityItem
      type={type}
      title={getSummary(proposal)}
      description={getActionVerb(type)}
      endDate={new Date(endDate)}
      href={getHref(slug, proposal)}
      icon={<ProposalRoundIcon round={proposal.round} />}
    />
  );
};
