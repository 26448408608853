import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import * as React from "react";
import styled from "styled-components";

const StyledLinearProgressBar = styled(LinearProgress)`
  &.${linearProgressClasses.determinate} {
    background-color: ${({ theme }) => theme.colors.moonMedium};
  }
  &.${linearProgressClasses.determinate}
    > .${linearProgressClasses.bar1Determinate} {
    background-color: background-color: ${({ theme }) => theme.colors.speech};
  }
`;
function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <StyledLinearProgressBar
            variant="determinate"
            {...props}
            sx={{
              height: 8,
              borderRadius: 5,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="caption" color="text.secondary">
          {" "}
          You&apos;re
          {""} {`${Math.round(props.value)}%`} done!
        </Typography>
      </Box>
    </div>
  );
}

export default function LinearWithValueLabel({
  completedItemsLength,
  total,
}: {
  completedItemsLength: number;
  total: number;
}) {
  const progress = (completedItemsLength / total) * 100;
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
