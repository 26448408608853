import { useMemo } from "react";

import { useUserContext } from "~/contexts/UserContext";

export function useUserInvitations() {
  const { user, loading } = useUserContext();

  return useMemo(() => {
    const workspaceInvitationsMap =
      [
        ...(user.workspaceInvitations ?? []),
        ...(user.proposalInvitations ?? []),
      ].reduce(
        (map, invitation) => {
          const existingWorkspaceInvite = map.get(invitation.workspace._id);
          if (existingWorkspaceInvite) {
            if (
              existingWorkspaceInvite.workspaceGuest &&
              !invitation.workspaceGuest
            ) {
              existingWorkspaceInvite.workspaceGuest = false;
            }
          } else {
            map.set(invitation.workspace._id, {
              workspace: invitation.workspace,
              workspaceGuest: invitation.workspaceGuest,
            });
          }
          return map;
        },
        new Map<
          string,
          {
            workspace: { _id: string; name: string };
            workspaceGuest: boolean;
          }
        >()
      ) ?? [];

    return Array.from(workspaceInvitationsMap.values());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [loading]);
  /* eslint-enable react-hooks/exhaustive-deps */
}
