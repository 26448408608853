import Link from "next/link";
import { css } from "styled-components";

import StopwatchIcon from "~/assets/icons/stopwatch.svg";
import { ActivityType } from "~/graphql/generated";
import useTimeToAdvance from "~/hooks/useTimeToAdvance";

import {
  ActivityLink,
  AgreementActivityAction,
  AgreementActivityIconWrap,
  AgreementActivityMessage,
  AgreementActivityWrap,
} from "./styles";

type ActivityItemProps = {
  type: ActivityType;
  href: string;
  title: JSX.Element | string;
  description: JSX.Element | string;
  icon: JSX.Element;
  endDate: Date;
};

export const ActivityItem = ({
  type,
  title,
  href,
  description,
  icon,
  endDate,
}: ActivityItemProps) => {
  const timeLeft = useTimeToAdvance(endDate);
  const showTimeLeft = type !== ActivityType.AcknowledgeRequest;

  return (
    <Link href={href} passHref>
      <ActivityLink>
        <AgreementActivityWrap type={type}>
          <div>
            <AgreementActivityMessage>{description}</AgreementActivityMessage>
            <AgreementActivityAction>{title}</AgreementActivityAction>
          </div>

          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 1rem;
            `}
          >
            {showTimeLeft && (
              <div
                css={css`
                  padding: 0.25rem;
                  font-size: 0.875rem;
                  display: flex;
                  align-items: center;
                  gap: 0.1875rem;
                `}
              >
                <StopwatchIcon
                  css={css`
                    height: 1rem;
                  `}
                />
                <div>{showTimeLeft ? `${timeLeft} left` : ""}</div>
              </div>
            )}
            <AgreementActivityIconWrap type={type}>
              {icon}
            </AgreementActivityIconWrap>
          </div>
        </AgreementActivityWrap>
      </ActivityLink>
    </Link>
  );
};
