import { FC } from "react";
import styled, { css } from "styled-components";

import { AgreementRoundColorways } from "~/browser/shared/colors";
import Emoji from "~/components/Emoji";
import { ActivityType, Round } from "~/graphql/generated";

export const UnderstandIcon: FC = (args) => (
  <Emoji symbol="🧠" label="Question mark" {...args} />
);

export const ImproveIcon: FC = (args) => (
  <Emoji symbol="💬" label="Speech balloon" {...args} />
);

export const FinalizeIcon: FC = (args) => (
  <Emoji symbol="🗳" label="Ballot box with ballot" {...args} />
);

export const AcknowledgeIcon: FC = (args) => (
  <Emoji symbol="✉️" label="An envelope" {...args} />
);

export const FeedbackIcon: FC = (args) => (
  <Emoji symbol="📊" label="A bar chart" {...args} />
);

export const ActivityLink = styled.a`
  color: ${({ theme }) => theme.colors.oil};
  display: block;
  text-decoration: none;
`;

export const AgreementActivityWrap = styled.div<{ type: ActivityType }>`
  background-color: ${({ theme }) => theme.colors.moonMedium};
  padding: 1rem;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 1px 5px 10px ${({ theme }) => theme.colors.moon};
  &:hover {
    transition: transform 0.2s;
    transform: scale(1.033);
  }

  ${({ type, theme }) => {
    switch (type) {
      case ActivityType.FeedbackRequest:
        return css`
          background-color: ${theme.colors.speechLight};
        `;
      case ActivityType.AcknowledgeRequest:
        return css`
          background-color: ${theme.colors.periwinkleLight};
        `;
      case ActivityType.AskQuestions:
      case ActivityType.AnswerQuestions:
        return css`
          background-color: ${theme.colors[
            AgreementRoundColorways[Round.Understand].secondary
          ]};
        `;
      case ActivityType.SuggestImprovements:
      case ActivityType.Improve:
        return css`
          background-color: ${theme.colors[
            AgreementRoundColorways[Round.Improve].secondary
          ]};
        `;

      case ActivityType.Decide:
      case ActivityType.MakeItWork:
      case ActivityType.ResolveObjections:
        return css`
          background-color: ${theme.colors[
            AgreementRoundColorways[Round.Finalize].secondary
          ]};
        `;
    }
  }};
`;

export const AgreementActivityIconWrap = styled.span<{ type: ActivityType }>`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 4px;

  ${({ type, theme }) => {
    switch (type) {
      case ActivityType.FeedbackRequest:
        return css`
          background-color: ${theme.colors.speechPurple};
        `;
      case ActivityType.AcknowledgeRequest:
        return css`
          background-color: ${theme.colors.periwinkle};
        `;
      case ActivityType.AskQuestions:
      case ActivityType.AnswerQuestions:
        return css`
          background-color: ${theme.colors[
            AgreementRoundColorways[Round.Understand].primary
          ]};
        `;
      case ActivityType.SuggestImprovements:
      case ActivityType.Improve:
        return css`
          background-color: ${theme.colors[
            AgreementRoundColorways[Round.Improve].primary
          ]};
        `;

      case ActivityType.Decide:
      case ActivityType.MakeItWork:
      case ActivityType.ResolveObjections:
        return css`
          background-color: ${theme.colors[
            AgreementRoundColorways[Round.Finalize].primary
          ]};
        `;
    }
  }}
`;

export const AgreementActivityAction = styled.div`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-bottom: 0.25rem;

  & .author {
    font-size: 0.875rem;
    font-weight: ${({ theme }) => theme.weights.regular};
    margin-left: 0.375rem;
  }
`;

export const AgreementActivityMessage = styled.div`
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const ActivityDescription = styled.div`
  line-height: 1.5;
`;

export const Activities = styled.div`
  margin-top: 30px;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ProposalTitle = styled.span`
  text-decoration: underline;
`;
