import { isBefore } from "date-fns";
import { useCallback } from "react";

import useLocalStorage from "./useLocalStorage";

export const useStoredTimeout = ({ key }: { key: string }) => {
  const [value, setValue] = useLocalStorage<number | null>(key, null);

  const setExpiry = useCallback((date: Date) => setValue(date.valueOf()), [
    setValue,
  ]);

  const expired = !value ? null : isBefore(new Date(value), new Date());

  return { expired, setExpiry };
};
