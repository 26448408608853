import { memo } from "react";
import type { FC } from "react";

import { RedirectToLogin } from "~/components/RedirectToLogin";
import { useUserContext } from "~/contexts/UserContext";
import { AnyObject } from "~/utils/types";

import WaitRoom from "../WaitRoom";

export const RequireAuth: FC<AnyObject> = ({ children }) => {
  const { loading, authenticated } = useUserContext();
  if (!authenticated) {
    if (loading) {
      return <WaitRoom />;
    }
    return <RedirectToLogin />;
  }

  return <>{children}</>;
};

export default memo<FC<AnyObject>>(RequireAuth);
