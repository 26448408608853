import Link from "next/link";
import type { FC } from "react";
import React from "react";
import styled from "styled-components";

import Header from "~/components/Header";
import Logo from "~/components/Logo";
import PageTitle from "~/components/PageTitle";
import { LogoContainer as DefaultLogoContainer } from "~/components/Sidebar/styles";
import {
  Content,
  ContentBody,
  ContentHeader,
} from "~/containers/Layouts/MainLayout/styles";
import { useUserContext } from "~/contexts/UserContext";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";

const NoButton = styled.div``;
const LogoContainer = styled(DefaultLogoContainer)`
  color: ${({ theme }) => theme.colors.oil};
  & a {
    color: ${({ theme }) => theme.colors.oil};
  }
`;

const WorkspaceNotFound: FC = ({ children }) => {
  const { authenticated } = useUserContext();
  const slug = useWorkspaceSlug();
  const safeLink = "/welcome/switch-workspaces";

  return (
    <div>
      <Content css={{ padding: 0 }}>
        <ContentHeader
          css={{ justifyContent: "space-between", padding: "1rem 2rem" }}
        >
          <LogoContainer css={{ marginLeft: 0 }}>
            <Link href={safeLink} passHref>
              <a href={safeLink}>
                <Logo />
              </a>
            </Link>
          </LogoContainer>
          <Header workspaceSlug={slug} actionButton={NoButton} />
        </ContentHeader>
        <ContentBody
          css={{ padding: "7rem", paddingTop: "2rem", marginTop: 0 }}
        >
          <main>
            <PageTitle>Workspace not found</PageTitle>
            <p>
              We could not find the requested workspace id:{" "}
              <strong>{slug}</strong>
            </p>
            <br />
            {authenticated && (
              <>
                <p>
                  <a href={safeLink}>Click here</a> to view your list of
                  workspaces.
                </p>
              </>
            )}

            {children}
          </main>
        </ContentBody>
      </Content>
    </div>
  );
};

export default WorkspaceNotFound;
