import type { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import LockIcon from "~/assets/icons/private.svg";
import DashboardActivities from "~/browser/dashboard/components/DashboardActivities";
import { NewFeaturePopupConnected } from "~/browser/shared/components/NewFeaturePopup";
import RequireAuth from "~/containers/Common/RequireAuth";
import WaitRoom from "~/containers/Common/WaitRoom";
import MainLayout from "~/containers/Layouts/MainLayout";
import { useUserContext } from "~/contexts/UserContext";
import useSessionStorage from "~/hooks/useSessionStorage";
import { useStarlingOverride } from "~/hooks/useStarlingOverride";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";
import { redirectFromRoot } from "~/shared/utils/postLoginRedirectPath";
import { getPageTitle } from "~/utils/getPageTitle";

const FromLinkInviteSnackbar = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.speech};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  margin: auto;
  padding: 0.5rem;
  bottom: 2rem;
  left: 50%;
  transition: visibility 0s linear 300ms, opacity 300ms;
  transform: translateX(-50%);
`;

const EllipsisText = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const sharePromptResponseOnlyIOption = {
  value: [],
  label: (
    <>
      <LockIcon width="9.5" height="12" />
      <EllipsisText>Only I</EllipsisText>
    </>
  ),
};

export const sharePromptResponsePrivateOption = {
  value: [],
  label: (
    <>
      <LockIcon width="9.5" height="12" />
      <EllipsisText>Private</EllipsisText>
    </>
  ),
};

const DashboardPage: NextPage = () => {
  const { user, loading: loadingUser } = useUserContext();
  const [, setLastPage] = useSessionStorage<string | null>("lastPage", null);
  const { push, query } = useRouter();
  const fromLinkInvite = query.source === "linkInvite";

  const workspaceId = useWorkspaceSlug();
  const starlingOverride = useStarlingOverride();

  const workspace = [
    ...(user?.workspaces || []),
    ...(user?.guestOfWorkspaces || []),
  ].find((workspace) => workspace._id === workspaceId);

  const [display, setDisplay] = useState(true);
  useEffect(() => {
    setLastPage(location.pathname);
    setTimeout(() => setDisplay(false), 3000);
  }, [setLastPage]);

  if (loadingUser) {
    return <WaitRoom />;
  }

  // Redirect if we're not supposed to be here
  if (workspaceId && !workspace && !starlingOverride) {
    push(redirectFromRoot({ user, query: {} }));
    return (
      <div
        css={{
          width: "100vw",
          height: "100vh",
          display: "grid",
          position: "absolute",
          textAlign: "center",
          placeItems: "center center",
          top: 0,
          left: 0,
        }}
      >
        You&apos;re not in this workspace. Redirecting...
      </div>
    );
  }

  return (
    <RequireAuth>
      <Head>
        <title>{getPageTitle("Dashboard")}</title>
      </Head>
      <MainLayout
        css={css`
          transition: margin-top 0.2s;
          @media (max-width: 600px) {
            margin-top: 0;
          }
        `}
      >
        <main>
          <DashboardActivities />
        </main>
        <NewFeaturePopupConnected />
        {fromLinkInvite && (
          <FromLinkInviteSnackbar
            css={{
              visibility: display ? "visible" : "hidden",
              opacity: display ? 1 : 0,
            }}
          >
            Congratulations! You&apos;ve been added to{" "}
            <strong>{workspace?.name ?? "a workspace!"}</strong>
          </FromLinkInviteSnackbar>
        )}
      </MainLayout>
    </RequireAuth>
  );
};

export default DashboardPage;
