import styled, { css } from "styled-components";

import GreenCircleCheckmark from "~/assets/icons/green-circle-checkmark.svg";
import ProgressBar from "~/browser/dashboard/components/ProgressBar";
import { getChecklistIcon } from "~/browser/dashboard/utils/getChecklistIcon";
import { useUserContext } from "~/contexts/UserContext";
import { useMarkChecklistItemCompletedMutation } from "~/graphql/generated";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";

const ChecklistContainer = styled.ul`
  padding-left: 0.075rem;
  flex-direction: column;
  gap: 0.625rem;
`;

const ListItemContainer = styled.a`
  display: flex;
  align-items: center;
  padding: 1rem 0.625rem 1rem 1rem;
  margin-bottom: 0.375rem;
  width: 25rem;
  height: 4.375rem;
  text-decoration: none;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
`;

const ItemTitleStyles = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.oil};
  margin-bottom: 0;
`;

const StrikeThroughItems = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.moonDark};
  text-decoration: line-through;
`;

const ItemTimelineStyles = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.oil};
  margin-top: 0.25rem;
`;
type ChecklistItemData = {
  id: string;
  title: string;
  icon: string;
  link?: string;
  timeline: string;
};

//we know the values of the itemId, so adding a union type will guarantee that the function errors when another argument as itemId is set
const getChecklistItems = (
  itemId:
    | "visitExplore"
    | "proposingFirstAgreement"
    | "notificationSetup"
    | "participateInFirstProposal"
    | "createAccount"
    | "whatIsMurmur"
    | "introduceMurmur"
    | "createWorkspace",
  workspaceId: string
): ChecklistItemData => {
  switch (itemId) {
    case "visitExplore":
      return {
        id: itemId,
        title: "Visit Explore",
        icon: "explore-navigation",
        link: `/${workspaceId}/explore`,
        timeline: "< 1 minute",
      };
    case "proposingFirstAgreement":
      return {
        id: itemId,
        title: "Proposing Your First Agreement",
        icon: "explore-glasses",
        link:
          "https://murmurlabs.notion.site/Proposing-Your-First-Agreement-58a80b70f4f64350af0975251de5d9e9",
        timeline: "2 minutes",
      };
    case "notificationSetup":
      return {
        id: itemId,
        title: "Set up Slack or Discord notifications",
        icon: "slack-notification",
        link: `/${workspaceId}/account`,
        timeline: "3 minutes",
      };
    case "participateInFirstProposal":
      return {
        id: itemId,
        title: "Participating in Your First Proposal",
        icon: "explore-glasses",
        link:
          "https://murmurlabs.notion.site/Participating-in-Your-First-Proposal-895cdde0da674c0d955b13ef6577afe2",
        timeline: "2 minutes",
      };
    case "createAccount":
      return {
        id: "createAccount",
        title: "Create an account",
        icon: "create-account",
        link: "",
        timeline: "",
      };
    case "whatIsMurmur":
      return {
        id: "whatIsMurmur",
        title: "What is Murmur?",
        icon: "explore-glasses",
        link:
          "https://murmurlabs.notion.site/What-is-Murmur-d5e16d9c06c348ff82704f102bd291c0",
        timeline: "5 minutes",
      };
    case "introduceMurmur":
      return {
        id: "introduceMurmur",
        title: "Read 'Introducing Murmur to your team'",
        icon: "handwave",
        link:
          "https://murmurlabs.notion.site/Introducing-Murmur-to-your-team-dd2d277658004b35844e0b70c10d4a80",
        timeline: "5 minutes",
      };
    case "createWorkspace":
      return {
        id: "createWorkspace",
        title: "Create your workspace",
        icon: "create-workspace",
        link: "",
        timeline: "",
      };
  }
};

//Creating the checklist item set for members with the workspaceId
const getMemberChecklist = (workspaceId: string): Array<ChecklistItemData> => [
  getChecklistItems("whatIsMurmur", workspaceId),
  getChecklistItems("participateInFirstProposal", workspaceId),
  getChecklistItems("notificationSetup", workspaceId),
  getChecklistItems("visitExplore", workspaceId),
  getChecklistItems("proposingFirstAgreement", workspaceId),
  getChecklistItems("createAccount", workspaceId),
];

//Creating the checklist item set for Admins with the workspaceId
const getAdminChecklist = (workspaceId: string): Array<ChecklistItemData> => [
  getChecklistItems("introduceMurmur", workspaceId),
  getChecklistItems("visitExplore", workspaceId),
  getChecklistItems("proposingFirstAgreement", workspaceId),
  getChecklistItems("participateInFirstProposal", workspaceId),
  getChecklistItems("notificationSetup", workspaceId),
  getChecklistItems("createAccount", workspaceId),
  getChecklistItems("createWorkspace", workspaceId),
];

//Creating the checklist item set for guests with the workspaceId
const getGuestChecklist = (workspaceId: string): Array<ChecklistItemData> => [
  getChecklistItems("whatIsMurmur", workspaceId),
  getChecklistItems("participateInFirstProposal", workspaceId),
  getChecklistItems("notificationSetup", workspaceId),
  getChecklistItems("createAccount", workspaceId),
];

const ChecklistItem = ({
  id,
  title,
  link,
  icon,
  timeline,
  isCompleted,
}: {
  id: string;
  title: string;
  icon: string;
  link?: string;
  timeline: string;
  isCompleted: boolean;
}) => {
  const workspaceId = useWorkspaceSlug();
  const [markChecklistItemCompleted] = useMarkChecklistItemCompletedMutation();

  return (
    <ListItemContainer
      target="_blank"
      href={link}
      onClick={() => {
        markChecklistItemCompleted({
          variables: {
            input: {
              workspaceId,
              checkedItem: id,
            },
          },
        });
      }}
    >
      {isCompleted ? (
        <GreenCircleCheckmark
          css={css`
            margin-right: 0.75rem;
          `}
        />
      ) : (
        getChecklistIcon(icon)
      )}
      <div>
        {isCompleted ? (
          <StrikeThroughItems>{title}</StrikeThroughItems>
        ) : (
          <ItemTitleStyles>{title}</ItemTitleStyles>
        )}
        {!isCompleted ? (
          <ItemTimelineStyles>{timeline}</ItemTimelineStyles>
        ) : (
          ""
        )}
      </div>
    </ListItemContainer>
  );
};

const useUserItemStatus = () => {
  const { user } = useUserContext();
  const workspaceId = useWorkspaceSlug();
  const isItemCompleted = (item: { id: string }) => {
    const completedChecklistItems = user.checklists?.find(
      (checklist) => checklist.workspaceId === workspaceId
    );
    return (
      !!completedChecklistItems?.completedItems.includes(item.id) ||
      item.id === "createWorkspace" ||
      item.id === "createAccount"
    );
  };

  return { isItemCompleted };
};

const GettingStartedChecklist = ({
  isAdmin,
  isGuest,
}: {
  isAdmin: boolean;
  isGuest: boolean;
}) => {
  const workspaceId = useWorkspaceSlug();
  const { isItemCompleted } = useUserItemStatus();
  let checklist = getMemberChecklist(workspaceId);

  if (isAdmin) {
    checklist = getAdminChecklist(workspaceId);
  } else if (isGuest) {
    checklist = getGuestChecklist(workspaceId);
  }

  // Sorting each items already checked off the list to the end
  checklist.sort((a, b) => {
    if (isItemCompleted(a) && !isItemCompleted(b)) {
      return 1;
    }
    if (!isItemCompleted(a) && isItemCompleted(b)) {
      return -1;
    }
    return 0;
  });

  //Total of completed items
  const total = checklist.length;
  const completedItemsLength = checklist.filter((item) => isItemCompleted(item))
    .length;
  return (
    <div>
      <div
        css={css`
          padding: 1.25rem;
          background-color: ${({ theme }) => theme.colors.moon};
          border-radius: 0.5rem;
        `}
      >
        <h2
          css={css`
            margin: 0;
            margin-bottom: 1rem;
          `}
        >
          Getting Started
        </h2>
        <p>Finish setting up your workspace.</p>
        <div
          css={{
            flexDirection: "column",
            gap: "0.625rem",
          }}
        >
          <ProgressBar
            total={total}
            completedItemsLength={completedItemsLength}
          />
          <ChecklistContainer
            css={{
              flexDirection: "column",
              gap: "0.625rem",
            }}
          >
            {checklist.map((item) => (
              <ChecklistItem
                key={item.id}
                {...item}
                isCompleted={isItemCompleted(item)}
              />
            ))}
          </ChecklistContainer>
        </div>
      </div>
    </div>
  );
};
export default GettingStartedChecklist;
