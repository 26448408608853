import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { useEffect, useState } from "react";

import { inflect } from "~/utils/common";

import useInterval from "./useInterval";

const INTERVAL = 1000;

/**
 * This hook contains the logic and side effects to display when the stage
 * will advance next.
 */
export default function useTimeToAdvance(nextAdvancement?: Date) {
  const [timeToAdvance, setTimeToAdvance] = useState<string>(
    differenceInTime({ nextAdvancement, currentTime: Date.now() })
  );

  useInterval(() => {
    if (nextAdvancement) {
      setTimeToAdvance(
        differenceInTime({ nextAdvancement, currentTime: Date.now() })
      );
    }
  }, INTERVAL);

  // if the proposal advances and a player is viewing the agreement, update the
  // time for the time to advance
  useEffect(() => {
    if (nextAdvancement) {
      setTimeToAdvance(
        differenceInTime({ nextAdvancement, currentTime: Date.now() })
      );
    }
  }, [nextAdvancement]);

  return timeToAdvance;
}

const MIN_DISPLAY_DAYS_REMAINING = 2;
const MIN_DISPLAY_HOURS_REMAINING = 1;
const MIN_DISPLAY_MINUTES_REMAINING = 0;

// Add a bit of buffer time for the cron job to run before we show "no time left"
const MIN_DISPLAY_LESS_THAN_MINUTE_REMAINING = -2;

const inflectDays = inflect("day");
const inflectHours = inflect("hour");
const inflectMinutes = inflect("minute");

function differenceInTime({
  nextAdvancement,
  currentTime,
}: {
  nextAdvancement?: Date;
  currentTime: number;
}) {
  if (!nextAdvancement) {
    return "";
  }

  const remainingDays = differenceInDays(nextAdvancement, currentTime);
  if (MIN_DISPLAY_DAYS_REMAINING <= remainingDays) {
    return `${remainingDays} ${inflectDays(remainingDays)}`;
  }

  const remainingHours = differenceInHours(nextAdvancement, currentTime);
  if (MIN_DISPLAY_HOURS_REMAINING <= remainingHours) {
    return `${remainingHours} ${inflectHours(remainingHours)}`;
  }

  const remainingMinutes = differenceInMinutes(nextAdvancement, currentTime);
  if (MIN_DISPLAY_MINUTES_REMAINING < remainingMinutes) {
    return `${remainingMinutes} ${inflectMinutes(remainingMinutes)}`;
  }

  if (MIN_DISPLAY_LESS_THAN_MINUTE_REMAINING < remainingMinutes) {
    return `Less than a minute`;
  }

  return `No time`;
}
