import ExploreNavigationIcon from "~/assets/icons/explore-navigation-icon.svg";
import GreenCircleCheckmark from "~/assets/icons/green-circle-checkmark.svg";
import GroupIcon from "~/assets/icons/group.svg";
import HandwaveIcon from "~/assets/icons/handwave-icon.svg";
import ExploreGlassesIcon from "~/assets/icons/invite-mail-icon.svg";
import SlackNotificationIcon from "~/assets/icons/slack-notification-icon.svg";

export function getChecklistIcon(iconName: string | undefined) {
  const style = {
    main: {
      marginRight: "0.75rem",
    },
  };
  switch (iconName) {
    case "handwave":
      return <HandwaveIcon style={style.main} />;
    case "explore-navigation":
      return <ExploreNavigationIcon style={style.main} />;
    case "explore-glasses":
      return <ExploreGlassesIcon style={style.main} />;
    case "slack-notification":
      return <SlackNotificationIcon style={style.main} />;
    case "create-workspace":
      return <GreenCircleCheckmark style={style.main} />;
    case "create-account":
      return <GreenCircleCheckmark style={style.main} />;
    default:
      // FIXME: pick a better default icon for checklists
      return <GroupIcon style={style.main} />;
  }
}
